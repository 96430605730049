var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('transition',{attrs:{"name":"fade-faster","mode":"out-in"}},[(_vm.showContactForm)?_c('contact-form',{attrs:{"shopname":_vm.accountName,"account-slug":_vm.accountSlug}}):(_vm.showCustomerRideOptionsSubstep)?_c('need-a-ride-substep',{attrs:{"ride-options":_vm.customerRideOptions,"show-book-now-button":_vm.showBookNowButton},on:{"ride-option-selected":_vm.handleRideOptionSelected}}):_c('div',{staticClass:"drop-off-step-main-wrap"},[_c('div',{staticClass:"drop-off-step"},[(_vm.waitingModeEnabled)?_c('div',[_c('div',{staticClass:"text-header-4 mb-12px"},[_vm._v("I would prefer to...")]),_c('div',{staticClass:"appointment-types-list"},[_c('appointment-type-card',{attrs:{"title":'Drop off my vehicle',"custom-drop-off-policy":_vm.dropOffPolicy,"description":_vm.appointmentTypeAvailabilityDescriptions.drop_off,"type":"drop_off","selected":_vm.selectedAppointmentType === 'drop_off'},nativeOn:{"click":function($event){return (() => {
                  _vm.analyticsTrackDropOffClick();
                  _vm.handleSelectAppointmentType('drop_off');
                }).apply(null, arguments)}}}),_c('appointment-type-card',{attrs:{"title":"Wait at the shop","description":_vm.appointmentTypeAvailabilityDescriptions.waiting,"type":"waiting","selected":_vm.selectedAppointmentType === 'waiting'},nativeOn:{"click":function($event){return (() => {
                  _vm.analyticsTrackWaitAtShopClick();
                  _vm.handleSelectAppointmentType('waiting');
                }).apply(null, arguments)}}})],1)]):_vm._e(),(!_vm.waitingModeEnabled && _vm.soonestAvailableTimeSlot.date)?_c('div',{staticClass:"mb-12px"},[_c('div',{staticClass:"step-title no-mb"},[_vm._v("Select a drop off time")]),(_vm.dropOffPolicy)?_c('div',{staticClass:"step-subtitle"},[_vm._v(" "+_vm._s(_vm.dropOffPolicy)+" ")]):_vm._e()]):_vm._e(),_c('transition',{attrs:{"name":"fade-fastest","mode":"out-in"}},[(_vm.selectedAppointmentType && !_vm.loading)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.displayLoader),expression:"displayLoader"}],key:_vm.selectedAppointmentType},[(!_vm.soonestAvailableTimeSlot.date)?_c('no-availability-calendar',{attrs:{"account-number":_vm.accountNumber,"within-working-hours":_vm.withinWorkingHours,"appointment-type":_vm.selectedAppointmentType},on:{"show-contact-form":function($event){_vm.showContactForm = true}}}):_vm._e(),(_vm.waitingModeEnabled && _vm.soonestAvailableTimeSlot.date)?_c('div',{staticClass:"step-title"},[_vm._v("Select a time ")]):_vm._e(),(_vm.soonestAvailableTimeSlot.date)?_c('div',{staticClass:"calendar-wrapper"},[_c('div',{staticClass:"calendar-header"},[_c('calendar-controls',{attrs:{"selected-month":_vm.selectedMonth,"selected-year":_vm.selectedYear},on:{"prev-week":_vm.goToPrevWeek,"next-week":_vm.goToNextWeek}}),_c('div',{ref:"daysWrapper",staticClass:"days-wrapper"},_vm._l((_vm.weekDays),function(day,dayIdx){return _c('calendar-day',{key:dayIdx,attrs:{"day":day,"week-moving":_vm.weekMoving,"is-day-in-the-past":_vm.isDayInThePast(dayIdx),"is-day-unavailable":_vm.isDayUnavailable(dayIdx),"is-day-selected":_vm.isDaySelected(dayIdx)},nativeOn:{"click":function($event){_vm.analyticsTrackDateSelectClick();
                      _vm.selectDate(dayIdx);}}})}),1)],1),_c('el-collapse-transition',[(_vm.showAvailabilitySlots)?_c('div',{staticClass:"calendar-body"},[_c('availability-slots',{attrs:{"selected-date":_vm.selectedDate,"selected-slot":_vm.selectedSlot,"all-time-slots":_vm.availableTimeSlots.all},on:{"slot-selected":_vm.handleAvailabilityTimeslotSelected}})],1):_vm._e()])],1):_vm._e()],1):_vm._e()])],1),_c('transition',{attrs:{"name":"fade-faster"}},[(_vm.selectedDate && _vm.selectedSlot)?_c('div',{staticClass:"sticked-to-bottom-button-wrapper"},[_c('div',{staticClass:"content-container"},[_c('div',{staticClass:"confirm-appt-time-wrapper"},[_c('div',[_c('div',{staticClass:"confirm-appt-time-text"},[_vm._v(" "+_vm._s(_vm.formattedSelectedDateAndTimeslot)+" ")]),_c('div',{staticClass:"confirm-appt-type-text"},[_vm._v(" "+_vm._s(_vm.formattedSelectedAppointmentTypeText)+" ")])]),(_vm.showAvailabilitySlots)?_c('button',{staticClass:"primary-btn",attrs:{"type":"button","disabled":!_vm.selectedSlot},on:{"click":function($event){_vm.analyticsTrackConfirmScheduleClick();
                  _vm.handleConfirmApptTimeBtnClicked();}}},[_vm._v(" "+_vm._s((_vm.customerRideOptions.length > 1 && _vm.selectedAppointmentType === 'drop_off') || !_vm.showBookNowButton ? 'Confirm' : 'Book now')+" ")]):_vm._e()])])]):_vm._e()])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }